import { array, boolean, lazy, mixed, number, object, string } from 'yup'

import { email, password, phone } from '../validation.fields'
import {
  testCheckFileSize,
  testFiles,
  testPasswordConfirmation,
  testPhoneNumber,
  testVerificationCode,
  validateBusinessAddressGeosuggest,
  validateDispatchAddressGB
} from '../validation.methods'
import { formatMessage } from '../validation.utils'

export const SignInPhoneSchema = object().shape({
  phone_number: phone.required(
    formatMessage({ defaultMessage: 'Phone number is required' })
  ),
  password: password
})

export const SignInEmailSchema = object().shape({
  email,
  password
})

export const first_name = string()
  .trim()
  .required(formatMessage({ defaultMessage: 'First name is required' }))
  .nullable()
  .min(3, formatMessage({ defaultMessage: 'First name is too short!' }))
  .max(25, formatMessage({ defaultMessage: 'First name is too long!' }))

export const last_name = string()
  .trim()
  .required(formatMessage({ defaultMessage: 'Last name is required' }))
  .nullable()
  .min(3, formatMessage({ defaultMessage: 'Last name is too short!' }))
  .max(25, formatMessage({ defaultMessage: 'Last name is too long!' }))

export const SignUpPhoneSchema = object().shape({
  first_name,
  last_name,
  phone_number: phone.required(
    formatMessage({ defaultMessage: 'Phone number is required' })
  ),
  password,
  password_confirmation: password
    .required(
      formatMessage({ defaultMessage: 'Password confirmation is required' })
    )
    .test(testPasswordConfirmation())
})

export const SignUpEmailSchema = object().shape({
  first_name,
  last_name,
  email,
  password,
  password_confirmation: password
    .test(testPasswordConfirmation())
    .required(
      formatMessage({ defaultMessage: 'Password confirmation is required' })
    )
})

export const ResetPasswordProfileSchema = object()
  .shape({
    old_password: string().required(
      formatMessage({ defaultMessage: 'Please, enter your current password' })
    ),
    new_password: password,
    confirm_password: password
      .required(
        formatMessage({ defaultMessage: 'Confirm Password is required' })
      )
      .test(testPasswordConfirmation({ fieldName: 'new_password' }))
  })
  .required()

export const ResetPasswordSchema = object()
  .shape({
    password,
    confirm_password: password
      .required(
        formatMessage({ defaultMessage: 'Confirm Password is required' })
      )
      .test(testPasswordConfirmation({ fieldName: 'password' }))
  })
  .required()

export const ForgotPasswordSchema = lazy((values, { context }) => {
  const { queryType } = context

  if (queryType === 'email') {
    return object().shape({
      email: email.required(
        formatMessage({ defaultMessage: 'Email is required' })
      )
    })
  }

  return object().shape({
    phone_number: phone.required(
      formatMessage({ defaultMessage: 'Phone number is required' })
    )
  })
})

export const PasswordVerificationSchema = object().shape({
  confirmation_code: string()
    .test(testVerificationCode())
    .required(formatMessage({ defaultMessage: 'Code is required' }))
})

export const BusinessAddressFormSchema = object().shape({
  street_no: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Street no is required' }))
    .min(1, formatMessage({ defaultMessage: 'Street no is too short!' }))
    .max(50, formatMessage({ defaultMessage: 'Street no is too long' })),
  street_name: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Street name is required' }))
    .min(1, formatMessage({ defaultMessage: 'Street name is too short!' }))
    .max(50, formatMessage({ defaultMessage: 'Street name is too long' })),
  city: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'City is required' }))
    .min(1, formatMessage({ defaultMessage: 'City is too short!' }))
    .max(50, formatMessage({ defaultMessage: 'City is too long' })),
  country: string()
    .required(formatMessage({ defaultMessage: 'Country is required' }))
    .nullable(),
  postal_code: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Post code is required' }))
    .min(1, formatMessage({ defaultMessage: 'Post code is too short!' }))
    .max(50, formatMessage({ defaultMessage: 'Post code is too long' })),
  label: string().optional()
})

export const BusinessAddressSuggestSchema = BusinessAddressFormSchema.shape({
  business_address_suggest: string()
    .test('is-valid-address', 'Address is not valid', (_, context) => {
      return validateBusinessAddressGeosuggest(context.parent, context)
    })
    .nullable()
})

export const SignUpBusinessFormSchema = object().shape({
  first_name,
  last_name,
  email,
  phone_number: phone.required(
    formatMessage({ defaultMessage: 'Phone number is required' })
  ),
  business_segment: string()
    .required(formatMessage({ defaultMessage: 'Business segment is required' }))
    .nullable(),
  custom_business_segment: string()
    .trim()
    .max(
      50,
      formatMessage({ defaultMessage: 'Comment to other segment is too long!' })
    ),
  business_location: string()
    .required(
      formatMessage({ defaultMessage: 'Business location is required' })
    )
    .nullable(),
  business_address_attributes: BusinessAddressFormSchema,
  business_address_suggest: string()
    .test('is-valid-address', 'Address is not valid', function (_, context) {
      const address = context.parent.business_address_attributes

      return validateBusinessAddressGeosuggest(address, context)
    })
    .nullable(),
  business_name: string()
    .trim()
    .min(3, formatMessage({ defaultMessage: 'Business name is too short!' }))
    .max(50, formatMessage({ defaultMessage: 'Business name is too long' }))
    .required(formatMessage({ defaultMessage: 'Business name is required' })),
  password,
  company_registration_number: string()
    .trim()
    .max(256, formatMessage({ defaultMessage: 'Business number is too long' }))
    .required(formatMessage({ defaultMessage: 'Business number is required' })),
  tax_number: string()
    .trim()
    .max(256, formatMessage({ defaultMessage: 'TAX id is too long' })),
  business_type: string()
    .required(
      formatMessage({ defaultMessage: 'Business structure is required' })
    )
    .nullable(),
  password_confirmation: password
    .required(
      formatMessage({ defaultMessage: 'Password confirmation is required' })
    )
    .test(testPasswordConfirmation())
})

export const CreateBusinessAccountSchema = object().shape({
  documents_attributes: array()
    .test(
      testFiles({
        name: 'documents_attributes',
        maxCount: 5
      })
    )
    .nullable()
    .test(testCheckFileSize({ size: 10 })),
  business_type: string()
    .required(formatMessage({ defaultMessage: 'Business type is required' }))
    .nullable()
})

export const ContactInformationSchema = object().shape({
  avatar_attributes: mixed().test(
    testCheckFileSize({
      size: 5
    })
  ),
  email: string()
    .nullable()
    .email(formatMessage({ defaultMessage: 'Invalid email' }))
    .max(256, formatMessage({ defaultMessage: 'Email is too long!' }))
    .when('oldEmail', {
      is: (oldEmail) => !!oldEmail,
      then: string()
        .nullable()
        .required(formatMessage({ defaultMessage: 'Email is required' }))
    }),
  phone_number: string()
    .nullable()
    .test(testPhoneNumber())
    .when('oldPhone', {
      is: (oldPhone) => !!oldPhone,
      then: string()
        .nullable()
        .required(formatMessage({ defaultMessage: 'Phone number is required' }))
    })
})

export const PersonalContactInformationSchema = ContactInformationSchema.shape({
  first_name,
  last_name
})

export const BusinessContactInformationSchema = ContactInformationSchema.shape({
  first_name,
  last_name,
  business_name: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Business Name is required' }))
    .nullable()
    .min(3, formatMessage({ defaultMessage: 'Business Name is too short!' }))
    .max(50, formatMessage({ defaultMessage: 'Business Name is too long!' })),
  store_description: string()
    .nullable()
    .max(
      400,
      formatMessage({ defaultMessage: 'Store Description is too long!' })
    ),
  custom_business_segment: string()
    .trim()
    .max(
      50,
      formatMessage({ defaultMessage: 'Comment to other segment is too long!' })
    )
})

export const UpdateBusinessAccountSchema = object().shape({
  company_registration_number: string()
    .trim()
    .max(
      256,
      formatMessage({ defaultMessage: 'Registration number is too long' })
    )
    .required(
      formatMessage({ defaultMessage: 'Registration Number is required' })
    ),
  business_type: string()
    .required(formatMessage({ defaultMessage: 'Business type is required' }))
    .nullable(),
  documents_attributes: array()
    .test(
      testFiles({
        name: 'documents_attributes',
        maxCount: 5
      })
    )
    .nullable()
    .test(testCheckFileSize({ size: 10 })),
  tax_number: string()
    .trim()
    .nullable()
    .max(256, formatMessage({ defaultMessage: 'TAX id is too long' }))
})

export const DeleteAccountSchema = object().shape({
  reason: string().required(
    formatMessage({ defaultMessage: 'Reason is required' })
  ),
  custom_reason: string()
    .trim()
    .test(
      'customReason',
      formatMessage({ defaultMessage: 'Reason is required' }),
      (value, context) => {
        if (context.parent.reason === 'other' && !value) {
          return false
        }

        return true
      }
    )
})

export const UpdateShippingAddressSchema = object().shape({
  addresses_attributes: array()
    .min(1, formatMessage({ defaultMessage: 'Address is required' }))
    .test(
      'Unique',
      formatMessage({ defaultMessage: 'Addresses should be unique' }),
      (values) => {
        if (!values) return true

        const addresses = values
          .filter((item) => !item._destroy)
          .map((item) => item.address)

        return new Set(addresses).size === addresses.length
      }
    )
})

export const ShippingReturnRulesSchema = object().shape({
  shipping_policy_info: string()
    .nullable()
    .max(2000, formatMessage({ defaultMessage: 'Max 2000 characters' })),
  accept_return: boolean(),
  return_paid_by: string()
    .nullable()
    .when('accept_return', {
      is: true,
      then: string()
        .nullable()
        .required(
          formatMessage({ defaultMessage: 'Returns Paid by is required' })
        )
    }),
  return_days: string()
    .nullable()
    .when('accept_return', {
      is: true,
      then: string()
        .nullable()
        .required(formatMessage({ defaultMessage: 'Return Days is required' }))
    }),
  return_address: object()
    .when('accept_return', {
      is: true,
      then: object()
        .nullable()
        .required(
          formatMessage({ defaultMessage: 'Return Address is required' })
        )
    })
    .nullable(),
  return_policy_info: string()
    .nullable()
    .max(2000, formatMessage({ defaultMessage: 'Max 2000 characters' })),
  dispatch_address: object()
    .nullable()
    .test(
      validateDispatchAddressGB({
        name: 'dispatch_address',
        message: formatMessage({
          defaultMessage:
            'Shipping services are currently only available for UK users. Please use Click & Collect as an alternative.'
        }),
        isOptional: true
      })
    )
})

export const CreateVehicleSchema = object().shape({
  makes: string()
    .required(formatMessage({ defaultMessage: 'Make is required' }))
    .nullable(),
  model: string()
    .required(formatMessage({ defaultMessage: 'Model is required' }))
    .nullable(),
  generation_id: string().nullable()
})

export const MyGarageSchema = object().shape({
  my_garage: string()
    .required(
      formatMessage({ defaultMessage: 'Select from My Garage is required' })
    )
    .nullable()
})

export const ContactUsSchema = object().shape({
  name: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Name is required' }))
    .nullable()
    .max(256, formatMessage({ defaultMessage: 'Name is too long!' })),
  phone: phone.notRequired(),
  email,
  subject: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Subject is required' }))
    .nullable()
    .max(256, formatMessage({ defaultMessage: 'Subject is too long!' })),
  message: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Message is required' }))
    .max(1000, formatMessage({ defaultMessage: 'Max 1000 characters' }))
})

export const SendConfirmationCodeSchema = object().shape({
  code: string()
    .trim()
    .required(
      formatMessage({ defaultMessage: 'Confirmation Code is required' })
    )
    .nullable()
})

export const ReviewSchema = object().shape({
  rate: number()
    .required(formatMessage({ defaultMessage: 'Rating is required' }))
    .min(1, formatMessage({ defaultMessage: 'Min rating is 1' }))
    .max(5, formatMessage({ defaultMessage: 'Max rating is 5' })),
  comment: string()
    .trim()
    .max(300, formatMessage({ defaultMessage: 'Max 300 characters' }))
})

export const ReplySchema = object().shape({
  comment: string()
    .trim()
    .nullable()
    .required(
      formatMessage({ defaultMessage: 'Please specify additional feedback' })
    )
    .max(300, formatMessage({ defaultMessage: 'Max 300 characters' }))
})

export const CreateMarketingUserSchema = object().shape({
  phone_number: phone.notRequired(),
  email,
  interest_makes: array()
    .when('customer_type', {
      is: (customerType) => customerType === 'private',
      then: array()
        .min(1, formatMessage({ defaultMessage: 'Please select an interest' }))
        .required(
          formatMessage({ defaultMessage: 'Please select an interest' })
        )
        .nullable()
    })
    .nullable(),
  user_business: string()
    .when('customer_type', {
      is: (customerType) => customerType === 'business',
      then: string()
        .required(
          formatMessage({ defaultMessage: 'My business is a required field' })
        )
        .nullable()
    })
    .nullable(),
  customer_type: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Customer Type is required' }))
})

export const CreateListingReportSchema = object().shape({
  main_reason: string()
    .required(formatMessage({ defaultMessage: 'Reason is required' }))
    .nullable(),
  alternative_reason: string()
    .trim()
    .nullable()
    .required(formatMessage({ defaultMessage: 'Please specify the reason' }))
    .max(300, formatMessage({ defaultMessage: 'Max 300 characters' })),
  attachments_attributes: array().test(
    testFiles({ name: 'images', isNotRequired: true })
  )
})

export const setHolidayModeSchema = object().shape({
  is_holiday_mode: boolean(),
  duration: string()
    .nullable()
    .when('is_holiday_mode', {
      is: (isHolidayMode) => isHolidayMode,
      then: string()
        .required(formatMessage({ defaultMessage: 'Duration is required' }))
        .nullable()
    })
})

export const VehicleMissingSchema = object().shape({
  make: string()
    .trim()
    .required(formatMessage({ defaultMessage: 'Make is required' }))
    .nullable(),
  model: string().nullable().trim()
})

export const CreateTimeFrameSchema = object().shape({
  days: object()
    .shape({
      monday: boolean(),
      tuesday: boolean(),
      wednesday: boolean(),
      thursday: boolean(),
      friday: boolean(),
      saturday: boolean(),
      sunday: boolean()
    })
    .test(
      'atLeastOneDay',
      formatMessage({ defaultMessage: 'At least one day must be selected' }),
      (days) => Object.values(days).some((day) => day === true)
    ),
  from_time: string()
    .required(formatMessage({ defaultMessage: 'Required' }))
    .nullable(),
  to_time: string()
    .required(formatMessage({ defaultMessage: 'Required' }))
    .nullable()
    .test(
      'isGreater',
      formatMessage({ defaultMessage: '"To" time must be after "From" time' }),
      function (value) {
        const { from_time } = this.parent

        if (!from_time || !value) return true

        const fromTime = Number(from_time.replace(':', ''))
        const toTime = Number(value.replace(':', ''))

        return toTime > fromTime
      }
    )
})

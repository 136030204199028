import { UseFormReturn, FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'

import Alert from 'app/services/Alert'
import ErrorHandler, { ErrorHandlerType } from 'app/services/ErrorHandler'

const useFormErrorHandler = <TFormValues = FieldValues>(
  setError?: UseFormReturn<TFormValues & FieldValues>['setError'],
  hideAlert = false,
  extractErrors?: (error: ErrorHandlerType) => Record<string, string>
) => {
  const intl = useIntl()

  const errorHandler = (data: ErrorHandlerType) => {
    const error = new ErrorHandler(data, false)

    const showAlert = () => {
      if (!hideAlert) {
        Alert.error(
          error.message ||
            intl.formatMessage({
              defaultMessage: 'Something went wrong'
            })
        )
      }
    }

    if (setError) {
      if (data?.errors && !error.message) {
        let transformErrors = {}

        if (extractErrors) {
          transformErrors = extractErrors(data.errors)
        }

        const selectedErrors = extractErrors ? transformErrors : data.errors

        Object.entries(selectedErrors).forEach(([key, message]) => {
          setError(key as any, { message: message as string })
        })
      } else {
        showAlert()
      }
    } else {
      showAlert()
    }
  }

  return errorHandler
}

export default useFormErrorHandler
